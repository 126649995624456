import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/pages/landingPageSections/officeStyle.js";

// office
import office1 from "assets/img/office/office_1.jpg";
import office2 from "assets/img/office/office_5.jpg";
import office3 from "assets/img/office/office_3.jpg";
import office4 from "assets/img/office/office_4.jpg";
import office5 from "assets/img/office/office_2.jpg";

const useStyles = makeStyles(officeStyle);

export default function SectionOffice() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.office}>
        <GridContainer className={classes.textCenter}>
          <GridItem
            md={8}
            sm={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <h2 className={classes.title}>De praktijk</h2>
            <h4 className={classes.description}>
              De praktijk is gelegen in de Platte Lostraat 580 A, 3010 Kessel-lo.<br/><br/>

              Op de oprit van de praktijk zijn drie parkeerplaatsen beschikbaar.
              Er kan ook geparkeerd worden op straat, enkel aan de kant van de praktijk.
              Aan de andere kant van de straat is het verboden te parkeren.
            </h4>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem md={4} sm={4}>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={office1}
              alt="office1"
            />
          </GridItem>
          <GridItem md={4} sm={4}>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={office2}
              alt="office2"
            />
          </GridItem>
          <GridItem md={4} sm={4}>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={office3}
              alt="office3"
            />
          </GridItem>
          <GridItem md={6} sm={6}>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={office4}
              alt="office4"
            />
          </GridItem>
          <GridItem md={6} sm={6}>
            <img
              className={classNames(
                classes.imgRaised,
                classes.imgFluid,
                classes.rounded
              )}
              src={office5}
              alt="office5"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
