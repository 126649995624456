import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import descriptionStyle from "assets/jss/material-kit-pro-react/pages/landingPageSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function SectionDescription() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classNames(classes.aboutDescription, classes.textCenter)}>
        <GridContainer>
          <GridItem
            md={8}
            sm={8}
            className={classNames(classes.mrAuto, classes.mlAuto)}
          >
            <h5 className={classes.description}>
              Welkom op de website van onze praktijk voor logopedie.
              <br/><br/>
              We zijn een privépraktijk waarin drie fijne logopedisten werken met elk eigen specialisaties. <br/>
              Door ons toe te spitsen op enkele specifieke stoornissen kunnen we u een betere behandeling garanderen. <br/>
              Dankzij gericht onderzoek, intern overleg en regelmatige bijscholingen staan wij garant voor een kwaliteitsvolle en individuele behandeling.
              <br/><br/>
              U kan bij ons op afspraak terecht voor advies, onderzoek of behandeling. Wilt u graag meer informatie? Neem dan gerust contact met ons op.
            </h5>
            <Button color="primary" href="/contact" round>
              Maak een afspraak
            </Button>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
