/*eslint-disable*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Camera from "@material-ui/icons/Camera";
import Palette from "@material-ui/icons/Palette";
import People from "@material-ui/icons/People";
import Add from "@material-ui/icons/Add";
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Badge from "components/Badge/Badge.js";
import Muted from "components/Typography/Muted.js";
import Parallax from "components/Parallax/Parallax.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Button from "components/CustomButtons/Button.js";

import elke from "assets/img/profile/elke_square.jpg";

import profilePageStyle from "assets/jss/material-kit-pro-react/pages/profilePageStyle.js";

const useStyles = makeStyles(profilePageStyle);

export default function ProfileElke() {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={6}>
          <div className={classes.profile}>
            <div>
              <img src={elke} alt="..." className={imageClasses} />
            </div>
            <div className={classes.name}>
              <h3 className={classes.title}>Elke Ven</h3>
              <h6>Behandelende logopediste em Stottertherapeute</h6>
            </div>
          </div>
        </GridItem>
      </GridContainer>
      <div className={classes.profileTabs}>
        <GridContainer>
          <GridItem
            xs={12}
            sm={12}
            md={6}
            className={classes.gridItem}>
            <h4 className={classes.title}>Achtergrond</h4>
            <p>
              Ik ben Elke Ven, logopediste en stottertherapeute. In de praktijk neem ik enkel nieuwe aanmeldingen met stotteren, broddelen en selectief mutisme aan. Naast het werk als zelfstandige logopediste, werk ik als zorgcoördinator op een school. Verder ben ik een erkend lid van de VVL (Vlaamse Vereniging van Logopedisten). Ook ik vind het zeer belangrijk om steeds op de hoogte te blijven van de recentste ontwikkelingen en wetenschappelijk onderbouwde methodes. Daarom volg ik frequent studiedagen, congressen en bijscholingen.
            </p>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={5}
            className={classes.gridItem}>
            <h4 className={classes.title}>Vakgebieden</h4>
            <ul className={classes.job}>

              <li>
                <a href='doelgroepen#stotteren'>Stotteren</a>
              </li>
              <li>
                <a href='doelgroepen#broddelen'>Broddelen</a>
              </li>
              <li>
                <a href='doelgroepen#mutisme'>Selectief mutisme</a>
              </li>
            </ul>
            <hr />
            <h4 className={classes.title}>Opleidingen</h4>
            <ul>
              <li>
                Master in de logopedische en audiologische wetenschappen (KU Leuven, 2012)
                </li>
              <li>
                Specialisatie stottertherapie (CIOOS - Centrum voor Inlichtingen, Onderzoek en Opleiding over Stotteren, Universiteit Antwerpen, 2013)
              </li>
              <li>
                Metaphon (Will Leijdecker, 2014)
              </li>
              <li>
                Basiscursus oromyofunctionele stoornissen (Peter Helderop, 2016)
              </li>
              <li>
                Banaba Buitengewoon Onderwijs (UCLL, 2018)
              </li>
              <li>
                Typ10 (C&Explore, 2019)
              </li>
              <li>
                Jaarlijks volg ik congressen, bijscholingen en studiedagen
              </li>
            </ul>
            <hr />
          </GridItem>
        </GridContainer>

      </div>
      <Clearfix />
    </div>
    </div>
  );
}
